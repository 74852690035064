import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { cloneDeep, uniqueId } from 'lodash';
import {
  HistoryIcon, LocationIcon, SearchBusDistrictIcon, SearchBusIcon, SearchFlightIcon,
} from 'public/icons';
import { addArea } from '@/slices/area/slices/areaSlice';
import { addHomePoints } from '@/slices/bus/area/slices/searchBusFormSlice';
import { useAppDispatch } from '@/utils/hook';
import type { SearchBusResultItemProps } from './type';
import { useHover } from '@/utils/hooks';

const replaceText = (str: string, color?: string) => {
  if (!str) return '';
  str = str.replace(/<b>/g, `<b style="color:${color}">`);
  return str;
};

export const ResultIcon = ({ type, name, color }: { type: number, name: string, color?: string }) => {
  let Icon = (
    <div style={{
      height: '19px',
      marginLeft: '-4px',
    }}
    >
      <LocationIcon color={color} />
    </div>
  );
  switch (type) {
    case 5:
      Icon = <SearchBusDistrictIcon color={color} />;
      break;
    case 15:
      if (name.toLowerCase().includes('bến xe')) {
        Icon = <SearchBusIcon color={color} />;
      }
      if (name.toLowerCase().includes('sân bay')) {
        Icon = <SearchFlightIcon color={color} />;
      }
      break;

    default:
      Icon = (
        <div style={{
          height: '19px',
          marginLeft: '-4px',
        }}
        >
          <LocationIcon color={color} />
        </div>
      );
      break;
  }
  return Icon;
};

const SearchBusResultItem: React.FC<SearchBusResultItemProps> = ({
  data,
  type,
  setClose,
  isRecent,
  isSearchResult,
  setIsInputEmpty,
  isInputEmptyRef,
  index,
  hoverIndex,
  onMouseEnter,
}) => {
  const newDataClone = cloneDeep(data);
  const {
    location, highlight, airports, stateName,
  } = newDataClone;
  const dispatch = useAppDispatch();
  const [ref, isNativeHover] = useHover();

  const isHover = index === undefined ? isNativeHover : String(index) === String(hoverIndex);

  const textHighLight = isSearchResult ? replaceText(highlight || location) : (highlight || location);
  return (
    <Stack
      ref={ref}
      onMouseEnter={() => (onMouseEnter ? onMouseEnter?.(index) : false)}
      onMouseOver={() => (onMouseEnter ? onMouseEnter?.(index) : false)}
      onMouseLeave={() => (onMouseEnter ? onMouseEnter?.('') : false)}
      spacing="4px"
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: '4px 16px',
        background: isHover ? '#0073d2' : 'white',
        cursor: 'pointer',
      }}
      onClick={() => {
        setClose();
        if (setIsInputEmpty) {
          setIsInputEmpty(true);
          isInputEmptyRef.current = true;
        }
        dispatch(addArea({
          area: newDataClone,
        }));
        dispatch(addHomePoints({
          data: newDataClone,
          type,
        }));
      }}
    >
      {isRecent ? (
        <HistoryIcon color={isHover ? 'white' : '#8897A5'} />
      ) : (
        <ResultIcon
          type={data.type}
          name={data.location}
          color={isHover ? 'white' : '#8897A5'}
        />
      )}
      <Stack
        sx={{ pl: 1 }}
      >
        <Stack
          component="div"
          spacing={1}
          direction="row"
        >
          <Stack
            direction="row"
            spacing="4px"
          >
            <Typography
              style={{
                fontSize: '14px',
                lineHeight: '20px',
                fontWeight: '500',
                color: isHover ? 'white' : 'black',
              }}
              fontWeight="500"
              dangerouslySetInnerHTML={{
                __html: `${textHighLight}`,
              }}
            />
            {data.type === 15 && (
              <Typography>
                {` - ${stateName}`}

              </Typography>
            )}

          </Stack>
        </Stack>
        <Stack
          spacing={0.5}
          marginTop="2px"
        >
          {airports.length > 0 && (
            <Stack
              component="div"
              key={uniqueId()}
              onClick={() => {
                newDataClone.airports = [airports[0]];
                if (setIsInputEmpty) {
                  setIsInputEmpty(true);
                  isInputEmptyRef.current = true;
                }
                setClose();
                dispatch(addArea({
                  area: newDataClone,
                }));
                dispatch(addHomePoints({
                  data: newDataClone,
                  type,
                }));
              }}
              spacing={1}
              direction="row"
            />
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SearchBusResultItem;
