import React from 'react';
import { styled } from '@mui/system';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { cloneDeep } from 'lodash';
import { HistoryIcon } from 'public/icons';
import { addArea } from '@/slices/area/slices/areaSlice';
import { addHomePoints } from '@/slices/area/slices/searchFlightFormSlice';
import { useAppDispatch } from '@/utils/hook';
import type { SearchFlightResultItemProps } from './index.d';
import { ResultIcon } from '@/containers/Bus/SearchBusTab/SearchBusResultItem';
import AirportItem from './AirportItem';
import { removeVietnameseTones } from '@/utils/stringConvert';

const replaceText = (str: string, color?: string) => {
  if (!str) return '';
  str = str.replace(/<b>/g, `<b style="color:${color}">`);
  return str;
};
// 0172CB
const SearchFlightResultItem: React.FC<SearchFlightResultItemProps> = ({
  data,
  type,
  setClose,
  isRecent,
  isSearchResult,
  setIsInputEmpty,
  isInputEmptyRef,
  onMouseEnter,
  index,
  hoverIndex,
  lang,
}) => {
  const newDataClone = cloneDeep(data);
  const {
    location, country, airports, highlight,
  } = newDataClone;
  const dispatch = useAppDispatch();

  const isHover = index === undefined ? false : String(index) === String(hoverIndex);

  const textHighLight = replaceText(highlight || location, isHover ? 'white' : '#0172CB');
  return (
    <Stack>
      <Stack
        sx={{
          cursor: 'pointer',
          flexDirection: 'row',
          padding: '4px 16px',
          background: isHover ? '#0073d2' : 'white',
        }}
      >
        {isRecent ? (
          <HistoryIcon color={isHover ? 'white' : '#8897A5'} />
        ) : (
          <ResultIcon
            color={isHover ? 'white' : '#8897A5'}
            type={data.type}
            name={data.location}
          />
        )}
        <Stack
          sx={{ pl: 1 }}
        >
          <Stack
            component="div"
            onClick={() => {
              setClose();
              if (setIsInputEmpty) {
                setIsInputEmpty(true);
                isInputEmptyRef.current = true;
              }
              dispatch(addArea({
                area: newDataClone,
              }));
              dispatch(addHomePoints({
                data: newDataClone,
                type,
              }));
            }}
            spacing={1}
            direction="row"
            onMouseEnter={() => (onMouseEnter ? onMouseEnter?.(index) : false)}
            onMouseOver={() => (onMouseEnter ? onMouseEnter?.(index) : false)}
            onMouseLeave={() => (onMouseEnter ? onMouseEnter?.('') : false)}
          >
            <Stack>
              <Box
                style={{
                  fontSize: '14px',
                  lineHeight: '20px',
                  fontWeight: '500',
                  color: isHover ? 'white' : 'black',
                }}
                dangerouslySetInnerHTML={{
                  __html: `${lang?.lang === 'en' ? removeVietnameseTones(textHighLight) : textHighLight}, ${lang?.lang === 'en' ? removeVietnameseTones(country) : country}`,
                }}
              />
              {airports.length > 1 && (
                <Typography
                  variant="caption"
                  color={isHover ? 'white' : '#343A40'}
                >
                  {airports.length}
                  {' '}
                  {lang?.SearchBar?.flightsTab?.airports || 'sân bay'}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <AirportItem
        lang={lang}
        indexProvince={index}
        hoverIndex={hoverIndex}
        airports={airports}
        isHover={isHover}
        isRecent={isRecent}
        isSearchResult={isSearchResult}
        location={location}
        onHoverItem={(id: string) => onMouseEnter?.(id)}
        onClickItem={(airport: any) => {
          newDataClone.airports = [airport];
          if (setIsInputEmpty) {
            setIsInputEmpty(true);
            isInputEmptyRef.current = true;
          }
          setClose();
          dispatch(addArea({
            area: newDataClone,
          }));
          dispatch(addHomePoints({
            data: newDataClone,
            type,
          }));
        }}
      />
    </Stack>
  );
};

export const WrapperClockIcon = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
`;

export default SearchFlightResultItem;
