import React from 'react';
import { style } from '@/styles/globals';
import FlightIcon from '@mui/icons-material/Flight';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { WrapperClockIcon } from './index';
import { ILangObj } from '@/slices/goyoloSeo/sliceType';
import { removeVietnameseTones } from '@/utils/stringConvert';

interface IAirportItem {
  airports: any;
  onClickItem: (airport: any) => any;
  isSearchResult?: boolean;
  isHover?: boolean;
  isRecent?: boolean;
  location?: string;
  indexProvince?: any;
  onHoverItem?: (index: string) => any
  hoverIndex?: any;
  lang?: ILangObj;
}

function AirportItem(props: IAirportItem) {
  const {
    airports,
    onClickItem,
    isSearchResult,
    isRecent,
    location,
    indexProvince,
    onHoverItem,
    hoverIndex,
    lang,
  } = props;
  return (
    <Stack
      spacing={0.5}
    >
      {airports.map((airport: any, index: number) => {
        const isHover = String(`${indexProvince}.${index}`) === String(hoverIndex);
        return (
          <Stack
            onMouseEnter={() => onHoverItem?.(`${indexProvince}.${index}`)}
            onMouseOver={() => onHoverItem?.(`${indexProvince}.${index}`)}
            onMouseLeave={() => onHoverItem?.(' ')}
            component="div"
            key={index}
            onClick={() => onClickItem(airport)}
            spacing={1}
            direction="row"
            sx={{
              cursor: 'pointer',
              padding: isRecent ? '8px 16px 8px 32px' : '4px 16px 4px 32px',
              ':hover': isHover ? {
                background: '#0073d2',
                '.item-airport-text': { color: 'white' },
                '.item-airport-icon': { color: 'white' },
              } : {},
            }}
          >
            {!!isSearchResult && (
              <WrapperClockIcon
                sx={{
                  color: style.color.black4,
                  fontSize: '16px',
                }}
              >
                <FlightIcon
                  className="item-airport-icon"
                  sx={{
                    color: style.color.black4,
                    transform: 'rotate(45deg)',
                    fontSize: '16px',
                  }}
                />
              </WrapperClockIcon>
            )}
            <Stack
              spacing={0}
            >
              <Box
                className="item-airport-text"
                sx={{
                  fontSize: '14px',
                  lineHeight: '16px',
                  fontWeight: '400',
                  // color: isHover ? 'white' : 'black',
                }}
                dangerouslySetInnerHTML={{
                  __html: `${airport.airportCode} - ${lang?.lang === 'en' ? airport?.nameEn : airport.highlight} `,
                }}
              />
              {isRecent ? null : (
                <Typography
                  // color={isHover ? 'white' : '#808080'}
                  variant="caption"
                  className="item-airport-text"
                >
                  <Typography
                    className="item-airport-text"
                    // color={isHover ? 'white' : '#808080'}
                    variant="caption"
                    sx={{ fontWeight: '500' }}
                  >
                    {`${parseFloat(airport.distance).toFixed(0)} km `}
                  </Typography>
                  {`${lang?.SearchBar?.flightsTab?.areaResult?.distanceToDowntown || 'đến trung tâm'} ${lang?.lang === 'en' ? removeVietnameseTones(location || '') : location} `}
                </Typography>
              )}
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

export default AirportItem;
