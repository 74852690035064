import { style } from '@/styles/globals';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Button } from '@mui/material';
import React from 'react';
import { styled } from '@mui/system';
import { pr } from '@/utils/pxToRem';

interface PlusButtonProps {
  type: 'plus' | 'minus';
  onClick: () => void;
  isDisable?: boolean;
  isBusForm?: boolean;
}
const StyledButton = styled(Button)({
  '&:hover': {
    background: style.color.black7,
  },
});

const PlusButton: React.FC<PlusButtonProps> = ({
  type, onClick, isDisable, isBusForm,
}) => (
  <StyledButton
    variant="contained"
    sx={{
      maxWidth: '28px',
      maxHeight: '28px',
      minWidth: '28px',
      minHeight: '28px',
      borderRadius: '3.8',
      boxShadow: 'none',
      opacity: isDisable ? '0.3' : '1',
      background: isBusForm ? 'white' : style.color.black7,
      '&:hover': {
        background: isBusForm ? 'white!important' : style.color.black7,
      },
    }}
    disabled={isDisable}
    onClick={onClick}
  >
    {type === 'plus' ? (
      <AddIcon
        sx={{
          fontSize: pr`16px`,
          color: style.color.black2,
        }}
      />

    ) : (
      <RemoveIcon
        sx={{
          fontSize: pr`16px`,
          color: style.color.black2,
        }}
      />

    )}
  </StyledButton>
);

export default PlusButton;
