module.exports = [{
  airports: [{
    id: '28284',
    airportCode: 'SGN',
    distance: '8.67314724377464',
    highlight: 'Sân bay Tân Sơn Nhất',
    nameEn: 'Tan Son Nhat International Airport',
    nameVn: 'Sân bay Tân Sơn Nhất',
  }],
  country: 'Việt nam',
  highlight: '<b>Hồ</b> <b>Chí</b> <b>Minh</b>',
  id: '29',
  stateId: '29',
  location: 'Hồ Chí Minh',
},
{
  airports: [{
    id: '102188',
    airportCode: 'HAN',
    distance: '41.26032025866256',
    highlight: 'Sân bay Nội Bài',
    nameEn: 'Noi Bai International Airport',
    nameVn: 'Sân bay Nội Bài',
  }],
  country: 'Việt nam',
  highlight: '<b>Hà</b> <b>Nội</b>',
  id: '24',
  stateId: '24',
  location: 'Hà Nội',
},
{
  airports: [{
    id: '135548',
    airportCode: 'DAD',
    distance: '13.971035870222636',
    highlight: 'Sân bay Đà Nẵng',
    nameEn: 'Da Nang International Airport',
    nameVn: 'Sân bay Đà Nẵng',
  }],
  country: 'Việt nam',
  highlight: '<b>Đà</b> <b>Nẵng</b>',
  id: '15',
  stateId: '15',
  location: 'Đà Nẵng',
},
{
  airports: [{
    id: '112068',
    airportCode: 'DLI',
    distance: '30.406504439481548',
    highlight: 'Sân bay Liên Khương',
    nameEn: 'Lien Khuong Airport',
    nameVn: 'Sân bay Liên Khương',
  }],
  country: 'Việt nam',
  highlight: '<b>Đà</b> <b>Lạt</b> - Lâm Đồng',
  id: '457',
  location: 'Đà Lạt',
},
{
  airports: [{
    id: '135552',
    airportCode: 'CXR',
    distance: '34.931696465666334',
    highlight: 'Sân bay Cam Ranh',
    nameEn: 'Cam Ranh Airport',
    nameVn: 'Sân bay Cam Ranh',
  }],
  country: 'Việt nam',
  highlight: '<b>Nha</b> <b>Trang</b> - Khánh Hòa',
  id: '417',
  stateId: '417',
  location: 'Nha Trang',
},
{
  airports: [{
    id: '135554',
    airportCode: 'PQC',
    distance: '9.396611372787397',
    highlight: 'Sân bay Phú Quốc',
    nameEn: 'Phu Quoc Airport',
    nameVn: 'Sân bay Phú Quốc',
  }],
  country: 'Việt nam',
  highlight: '<b>Phú</b> <b>Quốc</b> - Kiên Giang',
  id: '431',
  stateId: '431',
  location: 'Phú Quốc',
},
];
