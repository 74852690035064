import { style } from '@/styles/globals';
import { useAppSelector } from '@/utils/hook';
import { useBoolean } from '@/utils/hooks';
import {
  Button,
  Dialog, DialogContent, Stack, Typography,
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useState } from 'react';

const StyledButton = styled(Button)`
  :hover {
    background: ${style.primary.navy};
    color: ${style.color.white4};
    border: 1px solid ${style.neutral.border};
  }
  border: 1px solid ${style.neutral.border};
`;

interface TRedirectAlert {
  onConfirm: (params?: any) => void;
  params: any;
  open: boolean;
  setOpen: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  }
}

const RedirectAlert: React.FC<TRedirectAlert> = ({
  onConfirm, params, open, setOpen,
}) => {
  const lang = useAppSelector((state) => state.goyoloSeo?.lang);

  return (
    <Dialog
      open={open}
      onClose={() => setOpen.off()}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="xs"
    >
      <DialogContent>
        <Typography sx={{
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '20px',
        }}
        >
          {lang?.global?.goGoyoloText?.[0] || 'Bạn sẽ được chuyển qua website đặt vé máy bay'}
          {' '}
          <span style={{ color: style.primary.blue }}>Goyolo.com</span>
          {' '}
          {lang?.global?.goGoyoloText?.[1] || '- sản phẩm mới của VeXeRe.'}
        </Typography>
        <Stack
          spacing={1}
          direction="row"
          marginTop={2}
          sx={{
            justifyContent: 'space-between',
          }}
        >
          <StyledButton
            variant="outlined"
            sx={{
              width: '150px',
              color: style.primary.navy,
            }}
            onClick={() => setOpen.off()}
          >
            {lang?.global?.close || 'Đóng'}
          </StyledButton>
          <StyledButton
            onClick={() => onConfirm(params)}
            variant="outlined"
            sx={{
              width: '150px',
              background: style.primary.navy,
              color: style.color.white4,
            }}
          >
            {lang?.global?.agree || 'Đồng ý'}
          </StyledButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectAlert;
