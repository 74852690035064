module.exports = [
  {
    id: '24',
    longitude: 105.652579403,
    base_id: '1926',
    latitude: 20.974882679500094,
    name: 'Hà Nội',
    code: 'HNI',
    x_migrate_key: 24,
    state_id: '24',
    state_name: 'Hà Nội',
    type: 3,
    sub_type_id: '1',
    is_search_on_fe: 1,
    name_token: 'Hà Nội',
    alias: ['hn', 'noi ', 'ha', 'hanoi'],
    full_name: 'Hà Nội',
    airports: [
      {
        bbox: [105.807178, 21.221192, 105.807178, 21.221192],
        geometry: {
          coordinates: [105.807178, 21.221192],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '102188',
        properties: {
          AirportName_Vi: 'Sân bay Nội Bài',
          AirportName_En: 'Noi Bai International Airport',
          CityName_Vi: 'Hà Nội',
          CityName_En: 'Hanoi',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 102188,
          CITY: 'Hanoi',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'HAN',
          ICAO: 'VVNB',
          NAME: 'Noibai Intl  Airport',
          NAME_SHORT: 'Noibai Intl',
          OF_APT_ID: 3199,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 105.807175,
          Y: 21.221191,
          Z__feet_: 39,
          total_leng: 7000,
        },
        type: 'Feature',
        distance: 41.26032025866256,
      },
    ],
    train_stations: [
      {
        name: 'Ga Hà Nội',
        lat: 21.0250615,
        long: 105.8411814,
        id: 53,
        code: 'HNO',
        short_name: 'Hà Nội',
        distance: 26.46566049116218,
      },
      {
        name: 'Ga Long Biên',
        lat: 21.0399252,
        long: 105.8501554,
        id: 66,
        code: 'LBI',
        short_name: 'Long Biên',
        distance: 28.271218161161205,
      },
      {
        name: 'Ga Gia Lâm',
        lat: 21.0494904,
        long: 105.8832546,
        id: 48,
        code: 'GLA',
        short_name: 'Gia Lâm',
        distance: 32.94295653593288,
      },
      {
        name: 'Ga Đông Anh',
        lat: 21.1535075,
        long: 105.8524237,
        id: 26,
        code: 'DAN',
        short_name: 'Đông Anh',
        distance: 37.32878268879237,
      },
      {
        name: 'Ga Yên Viên',
        lat: 21.0842942,
        long: 105.9159123,
        id: 171,
        code: 'YVI',
        short_name: 'Yên Viên',
        distance: 38.8914130521164,
      },
    ],
    location: 'Hà Nội',
    highlight: '<b>Hà</b> <b>Nội</b>',
  },
  {
    id: '29',
    longitude: 106.6850563065,
    base_id: '1926',
    latitude: 10.768434817,
    name: 'Hồ Chí Minh',
    code: 'HCM',
    x_migrate_key: 29,
    state_id: '29',
    state_name: 'Hồ Chí Minh',
    type: 3,
    sub_type_id: '1',
    is_search_on_fe: 1,
    name_token: 'Hồ Chí Minh',
    alias: [
      'sg',
      'saigon',
      'sai gon',
      'hochiminh',
      'hcm',
      'sài gòn',
      'sài',
      'sai',
      'sài ',
      'sai ',
    ],
    full_name: 'Hồ Chí Minh',
    airports: [
      {
        bbox: [106.651856, 10.818797, 106.651856, 10.818797],
        geometry: {
          coordinates: [106.651856, 10.818797],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '28284',
        properties: {
          AirportName_Vi: 'Sân bay Tân Sơn Nhất',
          AirportName_En: 'Tan Son Nhat International Airport',
          CityName_Vi: 'Hồ Chí Minh',
          CityName_En: 'Ho Chi Minh City',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 28284,
          CITY: 'Ho Chi Minh City',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'SGN',
          ICAO: 'VVTS',
          NAME: 'Tansonnhat Intl  Airport',
          NAME_SHORT: 'Tansonnhat Intl',
          OF_APT_ID: 3205,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 106.651855,
          Y: 10.818797,
          Z__feet_: 33,
          total_leng: 6850,
        },
        type: 'Feature',
        distance: 8.67314724377464,
      },
    ],
    train_stations: [
      {
        name: 'Ga Sài Gòn',
        lat: 10.7815518,
        long: 106.6784072,
        id: 125,
        code: 'SGO',
        short_name: 'Sài Gòn',
        distance: 2.118191517444702,
      },
    ],
    location: 'Hồ Chí Minh',
    highlight: '<b>Hồ</b> <b>Chí</b> <b>Minh</b>',
  },
  {
    id: '48',
    longitude: 108.6891044845,
    base_id: '1926',
    latitude: 14.9826451355001,
    name: 'Quảng Ngãi',
    code: 'QNI',
    x_migrate_key: 48,
    state_id: '48',
    state_name: 'Quảng Ngãi',
    type: 3,
    sub_type_id: '2',
    is_search_on_fe: 1,
    name_token: 'Quảng Ngãi',
    alias: ['quangngai'],
    full_name: 'Quảng Ngãi',
    airports: [
      {
        bbox: [108.705889, 15.405944, 108.705889, 15.405944],
        geometry: {
          coordinates: [108.705889, 15.405944],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '135242',
        properties: {
          AirportName_Vi: 'Sân bay Chu Lai',
          AirportName_En: 'Chu Lai Airport',
          CityName_Vi: 'Quảng Nam',
          CityName_En: 'Quang Nam',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 135242,
          CITY: 'Chu Lai',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'VCL',
          ICAO: 'VVCA',
          NAME: 'Chu Lai  Airport',
          NAME_SHORT: 'Chu Lai',
          OF_APT_ID: 4157,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 108.70589,
          Y: 15.405944,
          Z__feet_: 10,
          total_leng: 7000,
        },
        type: 'Feature',
        distance: 61.23407373582022,
      },
    ],
    train_stations: [
      {
        name: 'Ga Quảng Ngãi',
        lat: 15.1209988,
        long: 108.7813511,
        id: 121,
        code: 'QNG',
        short_name: 'Quảng Ngãi',
        distance: 23.786521316666082,
      },
      {
        name: 'Ga Đức Phổ',
        lat: 14.8098323,
        long: 108.9468432,
        id: 39,
        code: 'DPH',
        short_name: 'Đức Phổ',
        distance: 43.82221851956309,
      },
    ],
    location: 'Quảng Ngãi',
    highlight: '<b>Quảng</b> <b>Ngãi</b>',
  },
  {
    id: '15',
    longitude: 108.1124498,
    base_id: '1926',
    latitude: 16.0925464,
    name: 'Đà Nẵng',
    code: 'DNA',
    x_migrate_key: 15,
    state_id: '15',
    state_name: 'Đà Nẵng',
    type: 3,
    sub_type_id: '1',
    is_search_on_fe: 1,
    name_token: 'Đà Nẵng',
    alias: ['danang'],
    full_name: 'Đà Nẵng',
    airports: [
      {
        bbox: [108.19937, 16.043917, 108.19937, 16.043917],
        geometry: {
          coordinates: [108.19937, 16.043917],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '135548',
        properties: {
          AirportName_Vi: 'Sân bay Đà Nẵng',
          AirportName_En: 'Da Nang International Airport',
          CityName_Vi: 'Đà Nẵng',
          CityName_En: 'Da Nang',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 135548,
          CITY: 'Danang',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'DAD',
          ICAO: 'VVDN',
          NAME: 'Danang Intl',
          NAME_SHORT: 'Danang Intl',
          OF_APT_ID: 3196,
          Other: 'International / Military',
          TYPE: 'I / M',
          TZ: 7,
          X: 108.19937,
          Y: 16.043917,
          Z__feet_: 33,
          total_leng: 7000,
        },
        type: 'Feature',
        distance: 13.971035870222636,
      },
    ],
    train_stations: [
      {
        name: 'Ga Đà Nẵng',
        lat: 16.0716481,
        long: 108.2093203,
        id: 37,
        code: 'DNA',
        short_name: 'Đà Nẵng',
        distance: 13.789925538421295,
      },
    ],
    location: 'Đà Nẵng',
    highlight: '<b>Đà</b> <b>Nẵng</b>',
  },
  {
    id: '26',
    longitude: 106.3682015615,
    base_id: '1926',
    latitude: 20.96050031000005,
    name: 'Hải Dương',
    code: 'HDG',
    x_migrate_key: 26,
    state_id: '26',
    state_name: 'Hải Dương',
    type: 3,
    sub_type_id: '2',
    is_search_on_fe: 1,
    name_token: 'Hải Dương',
    alias: ['haiduong'],
    full_name: 'Hải Dương',
    airports: [
      {
        bbox: [106.724989, 20.819386, 106.724989, 20.819386],
        geometry: {
          coordinates: [106.724989, 20.819386],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '135551',
        properties: {
          AirportName_Vi: 'Sân bay Cát Bi',
          AirportName_En: 'Cat Bi International Airport',
          CityName_Vi: 'Hải Phòng',
          CityName_En: 'Haiphong',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 135551,
          CITY: 'Haiphong',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'HPH',
          ICAO: 'VVCI',
          NAME: 'Cat Bi International Airport',
          NAME_SHORT: 'Cat Bi Intl',
          OF_APT_ID: 6188,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 106.72499,
          Y: 20.819386,
          Z__feet_: 13,
          total_leng: 2400,
        },
        type: 'Feature',
        distance: 52.32457523091701,
      },
      {
        bbox: [105.807178, 21.221192, 105.807178, 21.221192],
        geometry: {
          coordinates: [105.807178, 21.221192],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '102188',
        properties: {
          AirportName_Vi: 'Sân bay Nội Bài',
          AirportName_En: 'Noi Bai International Airport',
          CityName_Vi: 'Hà Nội',
          CityName_En: 'Hanoi',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 102188,
          CITY: 'Hanoi',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'HAN',
          ICAO: 'VVNB',
          NAME: 'Noibai Intl  Airport',
          NAME_SHORT: 'Noibai Intl',
          OF_APT_ID: 3199,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 105.807175,
          Y: 21.221191,
          Z__feet_: 39,
          total_leng: 7000,
        },
        type: 'Feature',
        distance: 84.52974062954833,
      },
    ],
    train_stations: [
      {
        name: 'Ga Hải Dương',
        lat: 20.947252,
        long: 106.329966,
        id: 50,
        code: 'HDU',
        short_name: 'Hải Dương',
        distance: 5.505391708027004,
      },
      {
        name: 'Ga Phú Thái',
        lat: 20.9658755,
        long: 106.5119282,
        id: 113,
        code: 'PTA',
        short_name: 'Phú Thái',
        distance: 19.416562337909497,
      },
      {
        name: 'Ga Cẩm Giàng',
        lat: 20.9670388,
        long: 106.1720671,
        id: 16,
        code: 'CGA',
        short_name: 'Cẩm Giàng',
        distance: 26.492066353440464,
      },
    ],
    location: 'Hải Dương',
    highlight: '<b>Hải</b> <b>Dương</b>',
  },
  {
    id: '417',
    base_id: '32',
    longitude: 109.1967488,
    latitude: 12.2387911,
    name: 'Nha Trang - Khánh Hòa',
    code: 'NT',
    x_migrate_key: '359',
    city_id: '417',
    city_name: 'Nha Trang',
    address: '',
    type: 5,
    sub_type_id: '17',
    is_search_on_fe: 1,
    state_id: '32',
    state_name: 'Khánh Hòa',
    name_token: 'Nha Trang',
    alias: ['nhatrang'],
    full_name: 'Nha Trang - Khánh Hòa',
    airports: [
      {
        bbox: [109.219372, 11.998153, 109.219372, 11.998153],
        geometry: {
          coordinates: [109.219372, 11.998153],
          type: 'Point',
        },
        geometry_name: 'the_geom',
        id: '135552',
        properties: {
          CITY: 'Nha Trang',
          COUNTRY: 'Vietnam',
          DST: 'U',
          IATA_FAA: 'CXR',
          ICAO: 'VVCR',
          NAME: 'Cam Ranh Airport',
          NAME_SHORT: 'Cam Ranh',
          OF_APT_ID: 6189,
          Other: 'International',
          TYPE: 'I',
          TZ: 7,
          X: 109.219376,
          Y: 11.998153,
          Z__feet_: 40,
          total_leng: 3048,
          AirportName_Vi: 'Sân bay Cam Ranh',
          AirportName_En: 'Cam Ranh Airport',
          CityName_Vi: 'Nha Trang',
          CityName_En: 'Nha Trang',
          CountryName_Vi: 'Việt nam',
          CountryName_En: 'Vietnam',
          VxrAreaId: 135552,
        },
        type: 'Feature',
        distance: 34.931696465666334,
      },
    ],
    train_stations: [
      {
        name: 'Ga Nha Trang',
        lat: 12.2484625,
        long: 109.1842369,
        id: 101,
        code: 'NTR',
        short_name: 'Nha Trang',
        distance: 2.2535646908063565,
      },
      {
        name: 'Ga Ninh Hoà',
        lat: 12.489559,
        long: 109.1239841,
        id: 95,
        code: 'NHO',
        short_name: 'Ninh Hoà',
        distance: 37.67730443146256,
      },
      {
        name: 'Ga Giã',
        lat: 12.70003574,
        long: 109.2236767,
        id: 47,
        code: 'GIA',
        short_name: 'Giã',
        distance: 66.78272179094591,
      },
    ],
    location: 'Nha Trang - Khánh Hòa',
    highlight: '<b>Nha</b> <b>Trang</b> - Khánh Hòa',
  },
];
